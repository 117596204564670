<template>
  <div class="c-app">
    <TheSidebar />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
    };
  },
  mounted() {
    //FETCH SEMESTER DATA
    let formSemester = new FormData();
    formSemester.append("txtAction", "get");
    formSemester.append("qSemester", "all");
    this.axios
      .post(this.$hostUrl + "php_action/semesterAPI.php", formSemester)
      .then((response) => {
        this.$store.commit("setSelectedSemester", response.data.mainData[0]);
        //console.log(this.$store.state.selectedSemester);
      });

    //BY PASS LOGIN
    if (this.$route.query.PersonID) {
      this.uploadingStat = 1;
      const formData = new FormData();
      formData.append("txtAction", "getByPassLogin");
      formData.append("txtUsername", this.$route.query.PersonID);
      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == true) {
            this.darkModal = true;
          } else {
            this.$cookies.set("user", response.data.userData);
            //this.$store.commit('setUserData', this.$cookies.get("user"));
            //this.$router.push({ path: "../dashboard" });
            location.replace(
              "https://beamingschool.com/" +
                this.$route.query.destination +
                "?LessonSubjectID=" +
                this.$route.query.LessonSubjectID +
                "&SubjectClassID=" +
                this.$route.query.SubjectClassID +
                "&SubLessonID=" +
                this.$route.query.SubLessonID +
                "&SubjectTechID=" +
                this.$route.query.SubjectTechID +
                "&ClassSchoolID=" +
                this.$route.query.ClassSchoolID +
                "&Room=" +
                this.$route.query.Room
            );
          }
        })
        .finally(() => {});
    } else {
      //IF STILL BLANK DATA
      if (this.userData == null) {
        alert("please login");
        this.$router.push({ path: "/pages/login" });
      }
    }
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
